.c {
  position: relative;
  background-color: #baffdb;
  color: #2b3630;
}

.c-wrapper {
  display: flex;
}

.c-title {
  flex: 1;
  text-align: center;
  font-size: 3em;
}

.c-info {
  flex: 1;
  text-align: center;
  padding-bottom: 20px;
}

.icon-links {
  display: flex;
  flex: 1;
  justify-content: center;
}

.icon {
  margin: 10px;
}

.resumeLink {
  font-size: 30px;
  font-weight: 300;
}
