.pl {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #54695e;
  color: aliceblue;
}

.pl-texts {
  font-size: 2em;
}

.pl-title {
  font-size: 50px;
  font-weight: 500px;
}

.pl-desc {
  margin: 20px, 0px;
}

.pl-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.p {
  width: 30%;
  height: 40vh;
  margin: 20px 10px;
  border: 2px solid red;
  border-radius: 10px;
  overflow: hidden;
}

.projectImage {
  width: 100%;
  height: 50%;
}
