.i {
  display: flex;
  height: 100%;
  color: #2b3630;
}
.i-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.i-left-wrapper {
  padding: 50px;
  height: 80%;
  display: flex;
  flex-direction: column;
}

.i-intro {
  font-size: 30px;
  font-weight: 300;
}

.i-name {
  font-size: 60px;
}

.i-title {
  height: 50px;
  overflow: hidden;
}

.i-title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #00dba4;
  display: flex;
  align-items: center;
}

.i-img {
  width: 50%;
  object-fit: contain;
  border-radius: 20%;
  position: absolute;
}

.i-bg {
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  background-color: #00dba4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.i-description {
  color: black;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  .i {
    flex-direction: column;
    height: 1000px;
    padding-top: 50px;
  }

  .i-left-wrapper {
    padding: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
  }

  .i-title {
    padding-left: 40px;
  }
}
