.a {
  padding: 50px;
  padding-top: 5px;
  background-color: #baffdb;
  color: #2b3630;
}

.a-title {
  font-weight: bold;
  text-align: center;
  font-size: 4em;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* @media screen and (max-width: 800px) {
  .logo {
    padding-left: 20px;
  }
} */
